<template>
    <div>
        <div id="chart">
            <v-chart class="e-chart" :option="option" autoresize />
        </div>
    </div>
</template>
  
<script>
  // @ is an alias to /src
    import { mapGetters, mapActions } from "vuex";
    import * as echarts from 'echarts/core';
    import {
        TitleComponent,
        TooltipComponent,
        LegendComponent
    } from 'echarts/components';
    import { GaugeChart } from 'echarts/charts';
    import { LabelLayout } from 'echarts/features';
    import { CanvasRenderer } from 'echarts/renderers';
    import VChart, { THEME_KEY } from "vue-echarts";

    echarts.use([
        TitleComponent,
        TooltipComponent,
        LegendComponent,
        GaugeChart,
        CanvasRenderer,
        LabelLayout
    ]);
  export default {
      name: "fact_panel",
      components: {
        VChart
      },

      provide() {
        return {
            [THEME_KEY]: "dark"
        };
      },
  
      data() {
          return {
            option: {
                title: {
                    text: this.$t('message.break_even_point')
                },
                series: [
                    {
                        type: 'gauge',
                        startAngle: 180,
                        endAngle: 0,
                        center: ['50%', '75%'],
                        radius: '90%',
                        min: 0,
                        max: 1,
                        splitNumber: 0,
                        axisLine: {
                            lineStyle: {
                                width: 30,
                                color: [
                                    [0.4, '#FDDD60'],
                                    [0.9, '#a4c9d3'],
                                    [1, '#1f7086']
                                ]
                            }
                        },
                        pointer: {
                            length: '100%',
                        },
                        axisLabel: {
                            color: '#464646',
                            fontSize: 20,
                            distance: -60,
                            rotate: 'tangential',
                            formatter: function (value) {
                                if(value){
                                    return value;

                                }
                                return '';
                            }
                        },
                        title: {
                            offsetCenter: [0, '-10%'],
                            fontSize: 20
                        },
                        detail: {
                            fontSize: 30,
                            offsetCenter: [0, '-35%'],
                            valueAnimation: true,
                            formatter: function (value) {
                                return Math.round(value * 100) + '';
                            },
                            color: 'inherit'
                        },
                        data: [
                            {
                                value: 0.7,
                                name: this.$t('message.fact')
                            }
                        ]
                    }
                ]
            }
          };
      },
  
      computed: {
          ...mapGetters({
              // list: "reasons/list",
              mode: "MODE"
          }),
      },
      methods: {
        ...mapActions({
            // updateList: "reasons/index",
        }),
      },
  };
</script>
  
<style>

.content__titlenight .cell {
  color: #688188;
}
.card__tablenight .mm_cards {
    background-color: #080c24;
}

</style>