<template>
    <div>
        <el-table
        :data="budgetComparison"
        style="width: 100%">
        <el-table-column
            prop="name"
            :label="$t('message.name')"
            >
        </el-table-column>
        <el-table-column :label="$t('message.for_unit')">
            <el-table-column
            prop="per_unit.plan"
            :label="$t('message.plan')"
            width="120">
            </el-table-column>
            <el-table-column
            prop="per_unit.fact"
            :label="$t('message.fact')"
            width="120">
            </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('message.per_volume')">
            <el-table-column
            prop="per_volume.plan"
            :label="$t('message.plan')"
            width="120">
            </el-table-column>
            <el-table-column
            prop="per_volume.fact"
            :label="$t('message.fact')"
            width="120">
            </el-table-column>
        </el-table-column>
        <el-table-column
            width="420"
            prop="diagram"
            :label="$t('message.diagram')"
        >
            <template slot-scope="scope">
            <div class="pb-5 pr-5">
                <el-slider
                v-model="scope.row.diagram"
                range
                :marks="marks"
                disabled
                >
                </el-slider>
            </div>
            </template>
        </el-table-column>

        <el-table-column :label="$t('message.deviations')">
            <el-table-column
            prop="ratio"
            label="+ / -"
            width="120">
            </el-table-column>
            <el-table-column
            prop="percentage"
            label="% %"
            width="120">
            </el-table-column>
            <el-table-column
            prop="grade"
            :label="$t('message.grade')"
            width="120">
            <template slot-scope="scope">
                <el-button :type="scope.row.color" circle></el-button>
            </template>
            </el-table-column>
        </el-table-column>
        </el-table>
    </div>
</template>
  
<script>
  // @ is an alias to /src
  import { mapGetters, mapActions } from "vuex";
  
  export default {
      name: "fact_panel",
      components: {
      },
  
      data() {
          return {
            marks: {
              0: '0$',
              50: '50$',
              90: '100$',
            },
            budgetComparison: [
              {
                diagram: [30, 57],
                name: 'Tom',
                per_unit: {
                  plan: 12.33,
                  fact: 8.33
                },
                per_volume: {
                  plan: 14.78,
                  fact: 9.93
                },
                ratio: '+380',
                percentage: '9%',
                color: 'primary',
              },
              {
                diagram: [12, 44],
                name: 'Ryereas',
                ratio: '-1.472',
                percentage: '-6%',
                color: 'danger'
              }
            ]
          };
      },
  
      computed: {
          ...mapGetters({
              // list: "reasons/list",
              mode: "MODE"
          }),
      },
      methods: {
        ...mapActions({
            // updateList: "reasons/index",
        }),
      },
  };
</script>
  
<style>

.content__titlenight .cell {
  color: #fff;
}

</style>