<template>
    <div class="">
      <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
        <div class="card-table-body ifon-add-title-block">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="caz-blocks-sarcho-title">
                <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.fact_panel") }} </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- end ifon-add-title-block -->
  
        <div class="card-table-header table-crm-smart" :class="mode ? 'content__titleday' : 'content__titlenight'">
          <BudgetComparisonTable></BudgetComparisonTable>
          
          <el-row :gutter="20" class="mt-5">
            <el-col :span="12">
              <BreakEvenPoint></BreakEvenPoint>
            </el-col>

            <el-col :span="12">
              <FactAnalysisPerformanceByProfit></FactAnalysisPerformanceByProfit>
            </el-col>
          </el-row>
        </div>
        
      </div>
    </div>
  </template>
  
<script>
  // @ is an alias to /src
  import { mapGetters, mapActions } from "vuex";
  import BudgetComparisonTable from './fact_components/budgetComparisonTable'
  import BreakEvenPoint from './fact_components/breakEvenPoint'
  import FactAnalysisPerformanceByProfit from './fact_components/factAnalysisPerformanceByProfit'
  
  
  export default {
      name: "fact_panel",
      components: {
        BudgetComparisonTable,
        BreakEvenPoint,
        FactAnalysisPerformanceByProfit
      },
  
      data() {
          return {

          };
      },
  
      computed: {
          ...mapGetters({
              // list: "reasons/list",
              mode: "MODE"
          }),
      },
      methods: {
          ...mapActions({
              // updateList: "reasons/index",
          }),
      
      },
  };
</script>
  
<style>

.content__titlenight .cell {
  color: #fff;
}

</style>